/* #region Sizes */

.w-25 {
  width: 25%;
}

.w-50px {
  width: 50px;
}

.w-50 {
  width: 50%;
}

.full-width {
  width: 100%;
}

.max-w-25 {
  max-width: 25%;
}

.max-w-50 {
  max-width: 50%;
}

.full-max-width {
  max-width: 100%;
}

.width-auto {
  width: auto;
}

.h-25 {
  height: 25%;
}

.h-50px {
  height: 50px;
}

.h-50 {
  height: 50%;
}

.h-100px {
  height: 100px;
}

.h-128px {
  height: 128px;
}

.h-50 {
  height: 50%;
}

.full-height {
  height: 100%;
}

.max-h-50 {
  max-height: 50%;
}

.full-max-height {
  max-height: 100%;
}

.height-auto {
  height: auto;
}

/* #endregion Sizes */

/* #region Displays */

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

/* #endregion Displays */

/* #region Flex */

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

.flex-7 {
  flex: 7;
}

.flex-8 {
  flex: 8;
}

.flex-9 {
  flex: 9;
}

.flex-10 {
  flex: 10;
}

.flex-11 {
  flex: 11;
}

.flex-12 {
  flex: 12;
}

.jc-center {
  justify-content: center;
}

.jc-space-between {
  justify-content: space-between;
}

.jc-end {
  justify-content: end;
}

.ai-center {
  align-items: center;
}

.gap-0_25rem {
  gap: 0.25rem;
}

.gap-0_5rem {
  gap: 0.5rem;
}

.gap-0_75rem {
  gap: 0.75rem;
}

.gap-1rem {
  gap: 1rem;
}

.gap-1_5rem {
  gap: 1.5rem;
}

.gap-2rem {
  gap: 2rem;
}

/* #endregion Flex */

/* #region Grids */

.d-grid {
  display: grid;
}

.d-inline-grid {
  display: inline-grid;
}

.grid-1col {
  grid-template-columns: repeat(1, 1fr);
}

.grid-1col-auto {
  grid-template-columns: auto;
}

.grid-2col {
  grid-template-columns: repeat(2, 1fr);
}

.grid-2col-auto {
  grid-template-columns: auto auto;
}

.grid-3col {
  grid-template-columns: repeat(3, 1fr);
}

.grid-4col {
  grid-template-columns: repeat(4, 1fr);
}

.grid-5col {
  grid-template-columns: repeat(5, 1fr);
}

.grid-6col {
  grid-template-columns: repeat(6, 1fr);
}

.grid-gap-0_5rem {
  grid-gap: 0.5rem;
}

.grid-gap-1rem {
  grid-gap: 1rem;
}

.grid-gap-2rem {
  grid-gap: 2rem;
}

/* #endregion Grids */

/* #region Margins */

.m-0_5 {
  margin: 0.5rem;
}

.mx-0_5 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.my-0_5 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mt-0_5 {
  margin-top: 0.5rem;
}

.mb-0_5 {
  margin-bottom: 0.5rem;
}

.ml-0_5 {
  margin-left: 0.5rem;
}

.mr-0_5 {
  margin-right: 0.5rem;
}

.m-1 {
  margin: 1rem;
}

.mx-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.m-2 {
  margin: 2rem;
}

.mx-2 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.ml-2 {
  margin-left: 2rem;
}

.mr-2 {
  margin-right: 2rem;
}

/* #endregion Margins */

/* #region Paddings */

.p-0_25 {
  padding: 0.25rem;
}

.px-0_25 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.py-0_25 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.pt-0_25 {
  padding-top: 0.25rem;
}

.pb-0_25 {
  padding-bottom: 0.25rem;
}

.pl-0_25 {
  padding-left: 0.25rem;
}

.pr-0_25 {
  padding-right: 0.25rem;
}

.p-0_5 {
  padding: 0.5rem;
}

.px-0_5 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-0_5 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pt-0_5 {
  padding-top: 0.5rem;
}

.pb-0_5 {
  padding-bottom: 0.5rem;
}

.pl-0_5 {
  padding-left: 0.5rem;
}

.pr-0_5 {
  padding-right: 0.5rem;
}

.p-1 {
  padding: 1rem;
}

.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.p-2 {
  padding: 2rem;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: 2rem;
}

.p-3 {
  padding: 3rem;
}

.px-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pl-3 {
  padding-left: 3rem;
}

.pr-3 {
  padding-right: 3rem;
}

.p-4 {
  padding: 4rem;
}

.px-4 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pt-4 {
  padding-top: 4rem;
}

.pb-4 {
  padding-bottom: 4rem;
}

.pl-4 {
  padding-left: 4rem;
}

.pr-4 {
  padding-right: 4rem;
}

.p-5 {
  padding: 5rem;
}

.px-5 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.py-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.pt-5 {
  padding-top: 5rem;
}

.pb-5 {
  padding-bottom: 5rem;
}

.pl-5 {
  padding-left: 5rem;
}

.pr-5 {
  padding-right: 5rem;
}

.p-6 {
  padding: 6rem;
}

.px-6 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.py-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.pt-6 {
  padding-top: 6rem;
}

.pb-6 {
  padding-bottom: 6rem;
}

.pl-6 {
  padding-left: 6rem;
}

.pr-6 {
  padding-right: 6rem;
}

.p-7 {
  padding: 7rem;
}

.px-7 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.py-7 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.pt-7 {
  padding-top: 7rem;
}

.pb-7 {
  padding-bottom: 7rem;
}

.pl-7 {
  padding-left: 7rem;
}

.pr-7 {
  padding-right: 7rem;
}

.p-8 {
  padding: 8rem;
}

.px-8 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.py-8 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.pt-8 {
  padding-top: 8rem;
}

.pb-8 {
  padding-bottom: 8rem;
}

.pl-8 {
  padding-left: 8rem;
}

.pr-8 {
  padding-right: 8rem;
}

/* #endregion Paddings */

/* #region Borders */

/* #region Border radius */

.border-radius-0 {
  border-radius: none;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-15 {
  border-radius: 15px;
}

.border-radius-20 {
  border-radius: 20px;
}

/* #endregion Border radius */

/* #endregion Borders */

/* #region Positions */

.relative-position {
  position: relative;
}

.absolute-position {
  position: absolute;
}

.fixed-position {
  position: fixed;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.z-index-10 {
  z-index: 10;
}

/* #endregion Positions */

/* #region Overflow */

.overflow-hidden {
  overflow: hidden;
}

.overflow-ellipsis {
  overflow: ellipsis;
}

/* #endregion Overflow */

/* #region Transformations */

.scale-1_2 {
  scale: 1.2;
}

.scale-1_5 {
  scale: 1.5;
}

/* #endregion */

/* #region Box sizing */

.border-box {
  box-sizing: border-box;
}

/* #endregion Box sizing */

/* #region Colors */

.text-black {
  color: black;
}

.text-white {
  color: white;
}

/* #endregion Colors */

/* #region Texts */

.rubik-font {
  font-family: Rubik, serif;
}

.nunito-font {
  font-family: Nunito, sans-serif;
}

.text-align-center {
  text-align: center;
}

.bold-text {
  font-weight: bold;
}

/* #region Wraps */

.no-wrap {
  text-wrap: nowrap;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* #endregion Wraps */

/* #endregion Texts */

/* #region Opacity */

.opacity-0 {
  opacity: 0;
}

.opacity-0_1 {
  opacity: 0.1;
}

.opacity-0_2 {
  opacity: 0.2;
}

.opacity-0_3 {
  opacity: 0.3;
}

.opacity-0_4 {
  opacity: 0.4;
}

.opacity-0_5 {
  opacity: 0.5;
}

.opacity-0_6 {
  opacity: 0.6;
}

.opacity-0_7 {
  opacity: 0.7;
}

.opacity-0_8 {
  opacity: 0.8;
}

.opacity-0_9 {
  opacity: 0.9;
}

.opacity-1 {
  opacity: 1;
}

/* #endregion Opacity */

/* #region Other simple classes */

.no-pointer-events {
  pointer-events: none;
}

.auto-pointer-events {
  pointer-events: auto;
}

/* #endregion */

/* #region Complex classes */

/* #region Containers */

.no-border-container {
  height: 100%;
  box-sizing: border-box;
}

.mid-container {
  height: 100%;
  box-sizing: border-box;

  padding: 2rem;
  border: 2px black solid;
  border-radius: 20px;
}

.top-container {
  height: 100%;
  box-sizing: border-box;

  padding: 2rem;
  border: 2px black solid;
  border-radius: 20px;
  border-top: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

/* #endregion Containers */

/* #region Collapsible */

.collapsible {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 500ms;
}

.collapsible.active {
  grid-template-rows: 1fr;
}

.collapsible > div
{
  overflow: hidden;
}

/* #endregion Collapsible */

/* #region Generic media queries */

@media (min-width: 0px)
{
  .flex-column-xs {
    flex-direction: column;
  }

  .flex-row-xs {
    flex-direction: row;
  }
}

@media (min-width: 600px)
{
  .flex-column-sm {
    flex-direction: column;
  }

  .flex-row-sm {
    flex-direction: row;
  }
}

@media (min-width: 900px)
{
  .flex-column-md {
    flex-direction: column;
  }

  .flex-row-md {
    flex-direction: row;
  }
}

@media (min-width: 1200px)
{
  .flex-column-lg {
    flex-direction: column;
  }

  .flex-row-lg {
    flex-direction: row;
  }
}

@media (min-width: 1536px)
{
  .flex-column-xl {
    flex-direction: column;
  }

  .flex-row-xl {
    flex-direction: row;
  }
}

/* #endregion Generic media queries */

/* #region Projects */

.project-display {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(1, 1fr);

  justify-items: center;
}

/* #region Media queries */

@media (min-width: 600px)
{
  .project-display {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px)
{
  .project-display {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 1200px)
{
  .project-display {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1536px)
{
  .project-display {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* #endregion Media queries */

/* #endregion Projects */

/* #region Overrides */

.MuiSkeleton-root {
  transform: none !important;
  -webkit-transform: none !important; 
}

.vertical-timeline-element-content .custom-content p
{
  margin: 0.25rem 0 0;
}

.vertical-timeline-element-content .custom-content p.bold-text
{
  font-weight: bold;
}

.vertical-timeline-element-content .custom-date.vertical-timeline-element-date {
  opacity: 1;
}

.vertical-timeline-element-content .custom-date-dark.vertical-timeline-element-date {
  color: white;
}

@media(min-width: 768px)
{
  .vertical-timeline-element-content .custom-date-light.vertical-timeline-element-date {
    color: white;
  }
}

@media(min-width: 1170px)
{
  .vertical-timeline-element-content .custom-date-light.vertical-timeline-element-date {
    color: black;
  }
}

/* #endregion Overrides */

/* #endregion Complex classes */